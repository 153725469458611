import React from "react";
import bgImage from "../../../static/assets/images/home-icons/lil-girl1.jpg";
const VideoCard = () => {
  return (
    <section className="video-card">
      <div className="video">
      </div>
      <img src={bgImage} width="100%" />
    </section>
  );
};

export default VideoCard;
