import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import donateImage from "../../static/assets/images/donate/donate.svg";
import i18n from "i18n-js";
import 'aos/dist/aos.css';
import AOS from 'aos';
const DonationOptions = () => {
  const [lang, setLang] = useState("en");
  useEffect(() => {
    const Script = document.createElement("script");
    //id should be same as given to form element
    const Form = document.getElementById("donateForm");
    Script.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    Script.setAttribute("data-payment_button_id", "pl_ImHUlRDQiGbfsT");
    Form.appendChild(Script);
    AOS.init({
      duration : 2000
    })
  }, []);
  return (
    <section className="donate-options donation-section-padding patch-bg-donate" id="donate">
      <Container className="start-donate-now">
        <div className="text-center" id="start-donate">
          <h1>{i18n.t('donating')}</h1>
        </div>
        <Row className="justify-content-center">
          <Col lg={6} >
            <form
              action="#"
              className="donate-options__form wow fadeInUp"
              data-wow-duration="1500ms"
            >
              <h3 className="text-center">{i18n.t('bank')}</h3>
              <div
                className="donation-form1-padding donation-options-mgn"
              >
                <Row noGutters className="jusstify-content-center">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p>
                      {" "}
                      <b>{i18n.t('acc-name')}</b>: Thereddot
                    </p>
                    <p>
                      {" "}
                      <b>{i18n.t('acc')}</b>: 10066504510
                    </p>
                    <p>
                      {" "}
                      <b>{i18n.t('acc-name')}</b>: IDFB0060284
                    </p>
                    <p>
                      {" "}
                      <b>{i18n.t('bank-add')}</b>: xyz,abc,Karnataka
                    </p>
                    <p>
                      {" "}
                      <b>{i18n.t('call-on')}</b>: +91 7587526017
                    </p>
                    <p>
                      <b>{i18n.t('email-us')}</b>:needhelp@thereddot.in
                    </p>
                  </Col>
                </Row>
              </div>
            </form>
          </Col>
          <Col lg={6} className="form2-margin-top" data-aos="zoom-in-up">
            <div class="text-center">
              <div>
                <img src={donateImage} width="20%" />
              </div>
              <h2 className="razorpay-section">{i18n.t('razorpay-sec')}</h2>
              <div className="mt-40" style={{ marginBottom: 'auto' }}>
                <form id="donateForm"> </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DonationOptions;
