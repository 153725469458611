import React from "react";
const BlockTitle = ({ tagLine, title }) => {
  return (
    <div className="block-title">
      <h3>
        {tagLine}
      </h3>
      <p>{title}</p>
    </div>
  );
};

export default BlockTitle;
