import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitle from "../block-title";
import i18n from "i18n-js";
import api from "../../utility/apis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCaptchaV2 from "react-google-recaptcha";
import $ from "jquery";
import validator from "validator";;
toast.configure({
  closeButton: (
    <>
      <p color="green" className="w-5 h-5 z-max">
        X
      </p>
    </>
  ),
});
var Recaptcha = require("react-recaptcha");
let recaptchaInstance;
const ContactFormOne = () => {
  const [articleId, setArticleId] = useState("");
  const [areaInterest, setAreaInterest] = useState();
  const [reCaptchaValue, setreCaptcha] = useState(false);
  const [emailErrorValid, setEmailError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    area_of_interest: "",
    message: "",
  });
  const [formError, setFormError] = useState({
    nameError: false,
    emailError: false,
    phoneError: false,
    area_of_interestError: false,
    messageError: "",
  });
  let a = {};
  const resetRecaptcha = () => {
    recaptchaInstance.reset();
  };
  const clearInput = (e) => {
    document.getElementById("name").value = "";
    document.getElementById("email").value = "";
    document.getElementById("phone").value = "";
    document.getElementById("interest").value = "";
    document.getElementById("message").value = "";
  };
  const handleChange = (event) => {
    // use spread operator
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
    console.log(
      form.name,
      form.email,
      form.phone,
      form.area_of_interest,
      form.phone,
      form.message
    );
  };
  const CloseButton = ({ closeToast }) => (
    <i className="material-icons" onClick={closeToast}>
      delete
    </i>
  );
  const getRecaptchaValue = (value) => {
    console.log(value);
    setreCaptcha(true);
  };
  const Test = ({ closeToast }) => (
    <i className="material-icons" onClick={closeToast}>
      X
    </i>
  );
  const getAreaOfInterestInfo = async () => {
    let { data } = await api
      .get("/trd-portal-area-of-interests?_sort=id:asc", 3000)
      .catch((err) => console.log("something went wrong!!!"));
    console.log("data", data);
    data && setAreaInterest(data);
  };
  const postToDb = async (e) => {
    e.preventDefault();
    let donateObj = {
      data: {
        name: form.name.trim(),
        email: form.email.trim(),
        phone_number: form.phone.trim(),
        area_of_interest: form.area_of_interest.trim(),
        message: form.message.trim(),
      }
    };
    donateObj = JSON.stringify(donateObj);
    console.log(donateObj);
    console.log(form.area_of_interest.value);
    if (
      form.name.trim() !== "" &&
      form.phone.trim() !== "" &&
      form.area_of_interest !== "" &&
      form.message.trim() !== ""
    ) {
      if (form.email && validator.isEmail(form.email)) {
        console.log("email", validator.isEmail(form.email));
        setEmailError(false);
        if (reCaptchaValue) {
          console.log("donate", donateObj);
          const form = document.getElementById("contact-us-form");
          $("#btn-send").text("Submitting . . .").attr("disabled", true);
          try {
            const donateSubmit = await api
              .post("/trd-portal-volunteer-requests", donateObj)
              .catch((err) => {
                toast.error("something went wrong");
              })
              .finally(() => {
                clearInput();
              });
            if (donateSubmit) {
              $("#btn-send").text("Submit").attr("disabled", true);
              clearInput();
              toast.success(
                "Thank you! One of our team members will contact you soon.",
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: false,
                  closeButton: true,
                  pauseOnFocusLoss: true,
                }
              );
              window.grecaptcha.reset();
            }
          } catch (error) {
            toast.error("something went wrong");
          } finally {
            clearInput("e");
          }
        } else {
          toast.error("Please verify that you are not a robot!", {
            // Set to 15sec
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
        }

      } else {
        setEmailError(true);
      }
    } else {
      a = {
        nameError: true,
        emailError: true,
        phoneError: true,
        area_of_interestError: true,
        messageError: "",
      };
      setFormError(a);
    }
  };
  useEffect(() => {
    getAreaOfInterestInfo().catch((err) =>
      console.log("something went wrong!!")
    );
  }, []);
  return (
    <>
      <section
        className="contact-page bg-contact contact-page-sec contact-patch-bg-donate contact-form-bg"
       id="donate-form"
      >
        <Container>
          <Row>
            <Col lg={5} md={12}>
              <div className="contact-page__content mb-40">
                <BlockTitle
                  title={i18n.t("feel-free-msg")}
                  tagLine={i18n.t("connect-with-us")}
                />
              </div>
            </Col>
            <Col lg={7} md={12} id="donate-dropdown">
              <form
                className="contact-form-validated contact-page__form form-one mb-40"
                id="contact-us-form"
              >
                <div className="form-group">
                  <div className="form-control">
                    {articleId}
                    <label htmlFor="name" className="sr-only">
                      Name*
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder={i18n.t("contact-your-name")}
                      value={form.name}
                      onChange={handleChange}
                      required
                    />
                    {formError.nameError && form.name.length == 0 ? (
                      <span
                      className="form-err-msg"
                      >
                        {i18n.t("name-err")}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="form-control">
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder={i18n.t("contact-your-email")}
                      value={form.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-control">
                    <label htmlFor="phone" className="sr-only">
                      Phone*
                    </label>
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      placeholder={i18n.t("contact-number")}
                      value={form.phone}
                      onChange={handleChange}
                    />
                    {formError.phoneError && form.phone.length == 0 ? (
                      <span
                       className="form-err-msg"
                      >
                        {i18n.t("phone-err")}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="form-control">
                    <label htmlFor="interest" className="sr-only">
                      {i18n.t("contact-interest")}:
                    </label>
                    {console.log(form.area_of_interest)}
                    <select
                      name="area_of_interest"
                      id="interest"
                      className="select-drop decorated classic dropdown-category"
                      placeholder="Area of interest*"
                      value={form.area_of_interest}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled selected>
                        {i18n.t("contact-interest")}
                      </option>
                      {areaInterest && areaInterest.data.length > 0 &&
                        areaInterest.data.map((item) => (
                          <option value={item.attributes.value}>
                            {`${i18n.t(`${item.attributes.value}`)}`}
                          </option>
                        ))}
                    </select>
                    {formError.area_of_interestError &&
                      form.area_of_interest == "" ? (
                      <span
                      className="form-err-msg"
                      >
                        {i18n.t("donate-option-err")}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="form-control form-control-full">
                    <label htmlFor="message" className="sr-only">
                      Message*
                    </label>
                    <textarea
                      name="message"
                      placeholder={i18n.t("write-msg")}
                      id="message"
                      value={form.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  {formError.emailError && form.message.length == 0 ? (
                    <span
                    className="form-err-msg"
                    >
                      {i18n.t("message-err")}
                    </span>
                  ) : (
                    <></>
                  )}
                  <div className="mb-4 text-center">
                    <ReCaptchaV2
                      sitekey={"6LdbXioeAAAAAJgUxjsZMBm7aFXOjWyHwuqqDgYd"}
                      onChange={getRecaptchaValue}
                    />
                  </div>
                  <div className="form-control text-center form-control-full">
                    <span>
                      <button
                        type="click"
                        className="thm-btn mr-2 clear-btn-color margin-right-clear                                                                "
                        onClick={clearInput}
                      >
                        {i18n.t("clear")}
                      </button>
                      <button
                        type="submit"
                        className="thm-btn "
                        onClick={postToDb}
                        id="btn-send"
                      >
                        {i18n.t("submit-btn")}
                      </button>
                    </span>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContactFormOne;
