import React from "react";
import Layout from "../components/layout";
import HeaderOne from "../components/header/header-one";
import StickyHeader from "../components/header/sticky-header";
import DonationOptions from "../components/donation-options";
import Footer from "../components/footer";
import VideoCard from "../components/videos/video-card";
import ContactFormOne from "../components/contact/contact-form-one";
import i18n from "i18n-js";
import 'aos/dist/aos.css';
import AOS from 'aos';
const Component = React.Component;
class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      name: `${i18n.t("donar")}`,
    };
  }
componentDidMount(){
      AOS.init({
        duration : 2000
      })
  }
  render() {
    const TAB_DATA = [
      [
        `${i18n.t("donor")}`,
        ` <p>${i18n.t("donar-text1")} </p>
        <p>${i18n.t("donar-text2" )}</p>
        <p>${i18n.t("donar-text3")}</p>`,
        "1",
      ],
      [
        `${i18n.t("content-contributor")}`, `${i18n.t("content-text")}`, "2"],
      [`${i18n.t("volunteer")}`, `${i18n.t("volunteer-text")}`, "3"],
      [`${i18n.t("campaign")}`, `${i18n.t("compaign-text")}`, "4"],
      [`${i18n.t("ambassador")}`, `${i18n.t("ambassador-text")}`, "5"],
    ];
    let content = "";
    let content_two = "";
    let title="";
    const tabs = TAB_DATA.map(([label, text, tab_num], i) => {
      title = this.state.active === i ? label: title;
      content = this.state.active === i ? text : content;
      content_two = this.state.active === i ? tab_num : content_two;
      return (
        <li
          className={this.state.active === i ? "tab active" : "tab"}
          key={label}
          num={i}
          onClick={(e) => {
            this.setState({
              active: parseInt(e.currentTarget.attributes.num.value),
            });
          }}
        >
          {label}
        </li>
      );
    });

    return (
      <>
        <section className="why-donate patch-bg-donate">
          <div >
            <div className="why-donate-div">
              <h2 className="">{i18n.t("donate-main-heading")}</h2>
              <div>
                <p>{i18n.t("donate-main-content")} </p>
                <p>{i18n.t("donate-main-content1")} </p>
              </div>
              <ul>
                <li>{i18n.t("donate-list1")}</li>
                <li>{i18n.t("donate-list2")}</li>
                <li>{i18n.t("donate-list3")}</li>
              </ul>
              <p>{i18n.t("donate-seek-help")}</p>
            </div>
          </div>
        </section>
        <section className="tabs patch-bg-donate">
          <div className="row justify-content-center">
            <div className="menu">
              <ul>{tabs}</ul>
            </div>
          </div>
          <div className="donate-heading">
            <h3 className="text-center">{title}</h3>
            <h4>
              <hr className="line"></hr>
            </h4>
          </div>
          <div
            className="donate-content text-center"
            data-aos="fade-right"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </section>
        {content_two == "1" && <DonationOptions />}
      </>
    );
  }
}

const Donate = () => {
  return (
    <Layout pageTitle="Donate">
      <HeaderOne />
      <StickyHeader />
      <VideoCard />
      <Tabs />
      <ContactFormOne />
      <Footer />
    </Layout>
  );
};
export default Donate;
